
/* 
.container{
    margin:10px;
    display: flex;
    color:var(--color);
    max-width: 640px;
    width: 90vw;
   
    border-radius: 10px;
    justify-content: space-between;
}

.col {
    box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
    padding: 10px;
    border-radius: 10px;
    margin: 0px 10px;
    

}
.col img{
    width: 100%;
}
.col button{
    background-color: var(--primary);
    width: 100%;
    border:none;
    color: #ffff;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;

} */



/* lib.module.css */
.container {
    margin:10px;
    color:var(--color);
    max-width: 600px;
    width: 90vw;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
    display: ruby;
  }
  
  .col {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    width: 200px; /* Adjust the width as per your layout */
  }
  
  .col img {
    width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .col button {
    background-color:var(--primary);
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 10px;
  }
  
  .col button:hover {
    background-color:var(--primary);
  }



  .col button:hover {
    background-color:var(--primary);
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination button {
    background-color:var(--primary);
    color: white;
    border: none;
    padding: 10px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .pagination .active {
    background-color:var(--primary);
  }


  .searchForm {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .searchInput {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    flex: 1;
  }
  
  .searchButton {
    padding: 10px 20px;
    font-size: 16px;
    background-color:var(--primary);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .searchButton:hover {
    background-color:var(--primary);
  }
  
  .resultsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .libCard {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .libTitle {
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 10px 0;
  }
  
  .libImage {
    max-width: 100%;
    height: auto;
    margin-top: 10px;
  }










@media only screen and (max-width: 768px) {
  .container{
      flex-direction: column;
  }
  .col{
      margin-top: 10px;
     
  }
}