.container{

 
    /* padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;

    max-width: 500px;
    margin: 10px */

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
    padding-right: 100px;
    text-decoration: none;

}

.Tabcont{
    
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;

    max-width: 350px;
    margin: 10px

}
.search{
    background-color:var(--card);
    width: 600px;
    display: flex;
    padding: 10px;
    border-radius: 10px;
}
.search input{
    flex: 1;
    outline: none;
    border:none;
    background-color: transparent;
}
.filters{
   display: none;
   text-decoration: none;
    }
.filters div{
    padding: 3px 10px;
    border-radius: 20px;
    text-decoration: none;
}

.filters a{
    padding: 3px 10px;
    border-radius: 20px;
    text-decoration: none;
}

.filters div:hover{
 color: #fff;
 background-color: var(--primary);
 cursor: pointer;
 text-decoration: none;
}   
@media only screen and (max-width: 768px) {
   .container{ 
       padding-right: 0px;
   }
   .search{
       width: 90vw;
   }
   .filters {
    /* gap: 40px; */
    /* margin-top: 10px;
    display: flex;
    width: 90vw;
    justify-content: space-around;
    text-decoration: none;

    flex-direction: row ;
    margin-left: -35px;
    position: fixed;
    background-color: #e0d9d929;
    border-radius: 10px; */


    margin-top: 10px;
    display: flex;
    width: 100vw;
    justify-content: space-around;
    text-decoration: none;
    flex-direction: row;
    margin-left: -9px;
    position: fixed;
    /* background-color: #e0d9d929; */
    border-radius: 10px;
    height: 30px;
    background-color: #fffffffc;
    margin: -13px 0px;
   }




   .filters div a{
    text-decoration: none;


   }
  
  
   
    }



    @media only screen and (max-width: 768px) {
        .Tabcont{
    
            padding: 20px;
            margin-bottom: 20px;
            border-radius: 8px;
        
        
            margin: 10px 176px;
        
        }
   
    
        
         }