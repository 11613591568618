.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
    padding-right: 100px;


}
.item{
    border-radius: 2px;
    display: flex;
    width: 600px;
    background-color: rgba(0, 0, 0, 0.100);
    display: flex;
    align-items: center;
    padding: 5px;
    font-size: large;
    cursor: pointer;
}
.item  div{
    margin-left: 5px;
}

@media only screen and (max-width: 768px) {
   .container{ 
       padding-right: 0px;
   }
   .item{
       width: 95vw;
   }
    }