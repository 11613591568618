.container{
    background-color: #ffffff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 0 auto;
    /* display: ruby; */
  }
  
.content{
    margin:20px;
    font-size: 1rem;

}
.intraction{
    display: flex;
    flex: 1;
}
.icons{
    margin-top: 30px;
    margin-left: 15px;
    cursor: pointer;

}



 