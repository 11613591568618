.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
    padding-right: 100px;
    justify-content: center;
    

}
.notiItem{
    display: flex;
    align-items: center;
    max-width: 600px;
    width: 95vw;
    margin-bottom: 10px;
    border-bottom: solid 1px rgb(133, 133, 133);
    padding-bottom: 5px;   
}
.notiItem img{
    border-radius: 100%;
    height: 40px;
    margin-right: 10px;
    width: 40px;
}
@media only screen and (max-width: 768px) {
    .container{
        padding-left: 5px;
        padding-right: 0px;
        
    }
}