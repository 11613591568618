.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
    padding-right: 100px;


}
@media only screen and (max-width: 768px) {
   .container{ 
       padding-right: 0px;
   }
    }