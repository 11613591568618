



.container{
 height: 100vh;
 width: 100vw;
 display: flex;
 justify-content: center;
 align-items: center;
 /* background-image: url('../images/a-conceptual-illustration-of-a-vast-vibr_aY-2GdhSTHW1nm8CD3L9yw_V-KFYmQpRfGCuD9jM5e9cQ.jpeg'); Add your image URL here */
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center; /* Keeps the image centered */
  background-repeat: no-repeat;


}


.main{
    max-width: 480px;
    width: 90vw;
   padding: 50px 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
}

h1{
 margin: 0px;
 margin-bottom: 10px;
}
.inputGroup{
  border: solid 1px rgb(185, 185, 185);
    background-color: rgb(245, 245, 245);
  display: flex;
  width: 70%;
  border-radius: 10px;
 
  
}
.inputGroup:hover{
  border: solid 1px var(--primary);
}
.inputGroup input{
  border: none;
  background-color: transparent;
  flex: 1;
  outline: none;
}
.form{
  width: 100%;
  margin-top: 40px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;  
}
.form input{
  font-size: large;
}
.icon{

padding: 5px;
border-radius: 10px;
color: rgb(97, 97, 97);

}
.btn{
  margin-top:40px;
  height: 30px;
  width: 70%;
  color: #fff;
  background-color: var(--primary);
  border:none;
  font-weight: 600;
  border-radius: 10px;
}
.header{
  width: 70%;
}
.forget{
  cursor: pointer;
  color: var(--primary);
  margin-top: 2px;
  width: 70%;
  text-align: right;
}