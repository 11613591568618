.container{
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 10px
  /* display: ruby; */
}










.content{
  font-size: 1rem;
  color: #333;
  line-height: 1.5;
  margin-bottom: 15px;

}

.intraction{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.icons {
  display: flex;
  align-items: center;
  gap: 14px;

}

.icons span {
  color: #777;
  font-size: 0.9rem;
}


.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modalContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    max-width: 100%;
  }
  
  /* .closeButton {
    background: none;
    border: none;
    font-size: 1.2rem;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  } */

 
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination button {
    background-color:var(--primary);
    color: white;
    border: none;
    padding: 10px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .pagination .active {
    background-color:var(--primary);
  }

.editOrDeleteIcons{

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 19px;

  float: inline-end;
}

  .promptContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    width: 36vw;
  }
  
  .promptContainer textarea {
    width: 100%;
    max-width: 600px;
    height: 100px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    font-size: 16px;
    resize: none;
  }
  
  .promptContainer button {
    padding: 10px 20px;
    background-color:var(--primary);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .promptContainer button:hover {
    background-color:var(--primary);
  }
  
  .responseContainer {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    max-width: 600px;
    width: 100%;
  }
  
  .responseContainer h3 {
    margin-top: 0;
    font-size: 18px;
    color: #333;
  }
  
  .responseContainer pre {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
    overflow-x: auto;
  }













.commentSection{
  margin-top: 20px;
}



  .searchForm {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    justify-content: center;
  }
  
  /* .searchInput {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
    max-width: 400px;
    margin-bottom: 10px;
  } */
  

  .searchInput {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    flex: 1;
  }
  .searchButton {
    padding: 10px 20px;
    background-color:var(--primary);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .searchButton:hover {
    background-color:var(--primary);
  }
  
  .resultsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .jobCard {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 20px;
    width: 100%;
    max-width: 300px;
    min-width: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .jobCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .cardHeader {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .employerLogo {
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin-right: 10px;
  }
  
  .jobInfo {
    text-align: left;
  }
  
  .jobTitle {
    margin: 0;
    font-size: 18px;
    color: #333;
  }
  
  .employerName {
    margin: 0;
    font-size: 16px;
    color: #666;
  }
  
  .cardBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .jobDetail {
    margin: 5px 0;
    font-size: 16px;
    color: #555;
  }
  
  .applyButton {
    background-color: var(--primary);

    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    display: inline-block;
    margin-top: 20px;
  }
  
  .applyButton:hover {
    background-color: #218838;



  }




  .learnCard {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .learnTitle {
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 10px 0;
  }
  
  .learnDescription {
    font-size: 14px;
  }




  @media (max-width: 768px) {
    iframe {
      max-width: 400px;
    }
  }


  
  @media (max-width: 768px) {
    .searchForm {
      flex-direction: column;
      align-items: center;
    }
  
    .searchInput {
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .resultsContainer {
      gap: 10px;
    }
  
    .jobCard {
      max-width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .jobCard {
      padding: 10px;
    }
  
    .employerLogo {
      width: 40px;
      height: 40px;
    }
  }




/* .searchForm {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: center;
}

.searchInput {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  max-width: 400px;
  margin-bottom: 10px;
}

.searchButton {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.searchButton:hover {
  background-color: #0056b3;
}

.resultsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.jobCard {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 20px;
  width: 100%;
  max-width: 300px;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.jobCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.cardHeader {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.employerLogo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin-right: 10px;
}

.jobInfo {
  text-align: left;
}

.jobTitle {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.employerName {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.cardBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.jobDetail {
  margin: 5px 0;
  font-size: 14px;
  color: #555;
}

.applyButton {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  display: inline-block;
  margin-top: auto;
}

.applyButton:hover {
  background-color: #218838;
}

@media (max-width: 768px) {
  .searchForm {
    flex-direction: column;
    align-items: center;
  }

  .searchInput {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .resultsContainer {
    gap: 10px;
  }

  .jobCard {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .jobCard {
    padding: 10px;
  }

  .employerLogo {
    width: 40px;
    height: 40px;
  }
} */


  /* .searchForm {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    justify-content: center;
  }
  
  .searchInput {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
    max-width: 400px;
    margin-bottom: 10px;
  }
  
  .searchButton {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .searchButton:hover {
    background-color: #0056b3;
  }
  
  .resultsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .jobCard {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 20px;
    width: 100%;
    max-width: 300px;
    min-width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .jobCard h3 {
    margin-top: 0;
  }
  
  .jobCard img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin-bottom: 10px;
  }
  
  .jobCard span {
    display: block;
    margin: 5px 0;
  }
  
  .jobCard a {
    color: #007bff;
    text-decoration: none;
    margin-top: 10px;
  }
  
  .jobCard a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .searchForm {
      flex-direction: column;
      align-items: center;
    }
  
    .searchInput {
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .resultsContainer {
      gap: 10px;
    }
  
    .jobCard {
      max-width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .jobCard {
      padding: 10px;
    }
  
    .jobCard img {
      width: 80px;
      height: 80px;
    }
  } */


  /* .searchForm {
    display: flex;
    margin-bottom: 20px;
  }
  
  .searchInput {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
  }
  
  .searchButton {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .searchButton:hover {
    background-color: #0056b3;
  }
  
  .resultsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .jobCard {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 20px;
    width: 100%;
    max-width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .jobCard h3 {
    margin-top: 0;
  }
  
  .jobCard p {
    margin: 5px 0;
  }
  
  .jobCard a {
    color: #007bff;
    text-decoration: none;
  }
  
  .jobCard a:hover {
    text-decoration: underline;
  } */










  
  @media (max-width: 768px) {
    .promptContainer {
      padding: 15px;
      
    }



    .promptContainer textarea {
      height: 80px;
      font-size: 14px;
    }
  
    .promptContainer button {
      padding: 8px 16px;
      font-size: 14px;
    }
  
    .responseContainer {
      padding: 15px;
    }
  
    .responseContainer h3 {
      font-size: 16px;
    }
  
    .responseContainer pre {
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .promptContainer textarea {
      height: 60px;
      font-size: 12px;
    }
  
    .promptContainer button {
      padding: 6px 12px;
      font-size: 12px;
    }
  
    .responseContainer {
      padding: 10px;
    }
  
    .responseContainer h3 {
      font-size: 14px;
    }
  
    .responseContainer pre {
      font-size: 12px;
    }
  }